import { gql } from "@apollo/client";
import customerFeatureSettingFragment from "./customerFeatureSettingFragment";
import customerSharingSettingsFragment from "./customerSharingSettingsFragment";

export const customerShortFragment = gql`
  fragment customerShortFragment on Customer {
    id
    title
    customIntakePageUrl
    integrations {
      constantContact {
        exists
      }
    }
    features {
      ...customerFeatureSettingFragment
    }
    maximumEmailsPerDay
    sharingSettings {
      ...customerSharingSettingsFragment
    }
  }
  ${customerFeatureSettingFragment}
  ${customerSharingSettingsFragment}
`;
