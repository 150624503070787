import Loading from "components/common/Loading";
import PageContainer from "components/common/PageContainer";
import Table from "components/common/Table";
import Tag from "components/common/Tag";
import {
  EmailCampaignItemFragmentFragment,
  EmailCampaignItemStatusEnum,
  useGetOneEmailCampaignWithItemsQuery,
} from "generated/graphql";
import usePaginationQueryParams from "hooks/usePaginationQueryParams";
import moment from "moment";
import { Link, useParams } from "react-router-dom";
import { PageTitle } from "routes/app-automations-create/shared";

export default function AppEmailCampaignsDetail() {
  const params = useParams<{ id: string }>();
  const {
    skip,
    limit,
    currentPage,
    onTablePaginationChange,
    resetTableParamsToDefault,
  } = usePaginationQueryParams();
  const { data, loading } = useGetOneEmailCampaignWithItemsQuery({
    variables: {
      emailCampaignId: (params?.id as string) || "",
      campaignItemsPagination: {
        skip,
        limit,
      },
    },
    skip: params?.id ? false : true,
  });
  if (loading) return <Loading />;
  return (
    <PageContainer>
      <div style={{ marginBottom: 24 }}>
        <PageTitle style={{ marginBottom: 8 }}>
          {data?.getOneEmailCampaign?.title}
        </PageTitle>
        {data?.getOneEmailCampaign?.recipientReferences?.map((item) => (
          <Tag key={item?.id}>{item?.title}</Tag>
        ))}
      </div>
      <Table
        dataSource={data?.getOneEmailCampaign?.emailCampaignItems?.results}
        columns={[
          {
            title: "email",
            render: (row: EmailCampaignItemFragmentFragment) => {
              if (row?.contactId) {
                return (
                  <Link to={`/app/contacts/${row?.contactId}`} target="_blank">
                    {row?.email}
                  </Link>
                );
              }
              return row?.email;
            },
          },
          {
            title: "Status",
            render: (row: EmailCampaignItemFragmentFragment) => {
              if (
                row?.status === EmailCampaignItemStatusEnum.Sent ||
                row?.status === EmailCampaignItemStatusEnum.Opened
              ) {
                return (
                  <>
                    <Tag>{row?.status}</Tag>
                  </>
                );
              }
              if (row?.status === EmailCampaignItemStatusEnum.Scheduled) {
                return (
                  <>
                    <Tag blue>{row?.status}</Tag>
                  </>
                );
              }
              if (
                row?.status === EmailCampaignItemStatusEnum.Failed ||
                row?.status === EmailCampaignItemStatusEnum.Bounced
              ) {
                return (
                  <>
                    <Tag red>{row?.status}</Tag>
                  </>
                );
              }
              return (
                <>
                  <Tag grey>{row?.status}</Tag>
                </>
              );
            },
          },

          {
            title: "scheduled for",
            render: (row: EmailCampaignItemFragmentFragment) =>
              row?.scheduledAt &&
              moment(parseInt(row?.scheduledAt) * 1000)?.format(
                // they are saved in seconds but moment expects milliseconds
                "MMM DD, YYYY hh:mma"
              ),
          },
        ]}
      />
    </PageContainer>
  );
}
